<template>
  <b-form-select
    v-model="clientId"
    :options="clientOptions">
  </b-form-select>
</template>

<script>
export default {
  name: 'ClientsFormSelect',
  props: {
    value: {
      type: Number,
    },
    skipDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      clients: [],
    };
  },
  computed: {
    clientOptions() {
      return [ ...(this.$store.getters['Core/getClients'] || []) ]
        .filter(client => (this.skipDisabled ? client.active : true))
        .sort((a, b) => a.display_name.localeCompare(b.display_name))
        .map(client => ({
          value: client.id,
          text: `${client.display_name} (ID: ${client.id})`,
        }));
    },
    clientId: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>
