<template>
    <div class="p-3">
        <div class="row">
            <div class="col-12">
                <h4>Whitelisted members for client {{ selectedClient.name }}</h4>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12">
                <strong class="col-2 float-left">Choose client:</strong>
                <clients-form-select
                  class="float-left form-control col-4"
                  v-model="selectedClientID">
                </clients-form-select>
                <b-button variant="success" v-b-toggle.form-add-member class="m-1 float-right">Add new member</b-button>
            </div>

            <div class="col-12">
                <b-collapse id="form-add-member" class="mt-2 mb-2">
                    <b-card>
                        <b-form>
                            <b-row>
                                <b-col sm="6">
                                    <label class="mt-2">First Name</label>
                                    <b-input class="mb-2 mr-sm-2 mb-sm-0" v-model="form.first_name"></b-input>
                                    <label class="mt-2">Last Name</label>
                                    <b-input class="mb-2 mr-sm-2 mb-sm-0" v-model="form.last_name"></b-input>
                                    <label class="mt-2">Date of birth (YYYYMMDD)</label>
                                    <b-input class="mb-2 mr-sm-2 mb-sm-0" v-model="form.dob" required></b-input>
                                </b-col>
                                <b-col sm="6">
                                    <label class="mt-2">Email</label>
                                    <b-input class="mb-2 mr-sm-2 mb-sm-0" v-model="form.email" required></b-input>

                                    <label v-b-popover.hover.top="'Internal ID on the client'"
                                           title="External ID" class="mt-2">
                                        External ID
                                        <feather type="info"></feather>
                                    </label>
                                    <b-input class="mb-2 mr-sm-2 mb-sm-0" v-model="form.external_id" required></b-input>

                                    <label class="mt-2">ASO Group</label>
                                    <b-input class="mb-2 mr-sm-2 mb-sm-0" v-model="form.aso_group" ></b-input>

                                </b-col>
                            </b-row>
                            <b-row>
                              <b-col>
                                <b-button variant="primary" class="mt-2 float-right" @click="addNewMember">Save</b-button>
                              </b-col>
                            </b-row>
                        </b-form>

                    </b-card>
                </b-collapse>
            </div>
            <div class="col-12">
                <table class="table">
                    <thead class="thead-light">
                    <tr>
                        <th class="text-center" scope="col">#</th>
                        <th class="text-center" scope="col">FirstName</th>
                        <th class="text-center" scope="col">LastName</th>
                        <th class="text-center" scope="col">DOB</th>
                        <th class="text-center" scope="col">Email</th>
                        <th class="text-center" scope="col">ExternalID</th>
                        <th class="text-center" scope="col">ASO Group</th>
                        <th class="text-center" scope="col">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="member in members" :key="member.id">
                        <td class="align-middle text-center">{{ member.id }}</td>
                        <td class="align-middle text-center">{{ member.first_name }}</td>
                        <td class="align-middle text-center">{{ member.last_name }}</td>
                        <td class="align-middle text-center">{{ member.dob }}</td>
                        <td class="align-middle text-center">{{ member.email }}</td>
                        <td class="align-middle text-center">{{ member.external_id }}</td>
                        <td class="align-middle text-center">{{ member.aso_group }}</td>
                        <td class="align-middle text-center">
                            <b-button @click="showDeleteConfirm(member.id)" variant="danger">
                                <feather type="trash"></feather>
                            </b-button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <b-row align-h="center">
            <b-col sm="4" align-self="center">
                <b-button variant="outline-success" block
                          v-if="showLoadMoreButton"
                          @click="loadMore">Load more</b-button>
            </b-col>
        </b-row>

    </div>
</template>

<script>
import axios from 'axios';
import ClientsFormSelect from '@/components/Inputs/ClientsFormSelect.vue';

export default {
  name: 'ClientWhitelist',
  components: { ClientsFormSelect },
  filters: {},
  data() {
    return {
      form: {
        first_name: '',
        last_name: '',
        dob: '',
        email: '',
        external_id: '',
      },
      selectedClient: {},
      selectedClientID: null,
      members: [],
      totalNumberOfMembers: 0,
      currentPage: 0,
      resultsPerPage: 10,
    };
  },
  computed: {
    clients() {
      return this.$store.getters['Core/getClients'] || [];
    },
    showLoadMoreButton() {
      return this.members.length < this.totalNumberOfMembers;
    },
  },
  beforeMount() {
    const { clientID } = this.$route.params;
    this.$store.dispatch('Core/fetchClients').then(() => {
      this.selectedClientID = Number(clientID) || null;
    });
  },
  methods: {
    showDeleteConfirm(whitelistID) {
      this.$bvModal.msgBoxConfirm('Are you sure?')
        .then(value => {
          if (!value) {
            return;
          }
          this.deleteMember(whitelistID);
        });
    },
    async deleteMember(id) {
      try {
        await axios.delete(`v1/clients/${this.selectedClientID}/whitelist/${id}`);
        this.$noty.success('New member deleted');
        this.members = this.members.filter(obj => obj.id !== id);
      } catch (error) {
        this.$noty.error('Failed to delete member');
      }
    },
    async addNewMember() {
      try {
        await axios.post(
          `v1/clients/${this.selectedClientID}/whitelist`,
          this.form,
          { endpoint: 'eligibility' },
        );
        this.$noty.success('New member added');
        this.$root.$emit('bv::toggle::collapse', 'form-add-member');
        this.fetchMembers();
      } catch (error) {
        this.$noty.error('Failed to add member', error);
        if (!error.response || !error.response.data || !error.response.data.error_description) {
          return;
        }

        Object.keys(error.response.data.error_description).forEach(k => {
          error.response.data.error_description[k].forEach(msg => {
            this.$noty.error(msg);
          });
        });
      }
    },
    loadMore() {
      this.currentPage += 1;
      this.fetchMembers();
    },
    async fetchMembers() {
      try {
        const { data } = await axios.get(
          `v1/clients/${this.selectedClientID}/whitelist`,
          {
            endpoint: 'eligibility',
            params: {
              offset: this.resultsPerPage * this.currentPage,
              limit: this.resultsPerPage,
              sort_order: 'desc',
              sort_by: 'id',
            },
          },
        );
        this.members = data.data;
        this.totalNumberOfMembers = data.pagination.total;
      } catch (error) {
        this.$noty.error('Failed to load members', error);
        if (!error.response || !error.response.data || !error.response.data.error_description) {
          return;
        }

        Object.keys(error.response.data.error_description).forEach(k => {
          error.response.data.error_description[k].forEach(msg => {
            this.$noty.error(msg);
          });
        });
      }
    },
  },
  watch: {
    selectedClientID(selectedID) {
      this.selectedClient = this.clients.find(c => c.id === selectedID);
    },
    selectedClient() {
      this.members = [];
      this.totalNumberOfMembers = 0;
      this.fetchMembers();
    },
  },
};
</script>
